import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import Form from "react-bootstrap/Form"; 
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import { Spinner } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import '../../style/login.css'
import { useEffect } from "react";
import { serverURL } from "../../serverURL";


export function Login(props){
    const client = axios.create()
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        'username':'',
        'password':''
    })
    const [msg, setMsg] = useState('')
    const [buttonTxt, setButtonTxt] = useState('로그인')
    const [buttonDisable, setButtonDisable] = useState(true)

    const onChange = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name] : e.target.value
        })
    }

    useEffect(()=>{
        if(userInfo.password !== '' && userInfo.username !== '') {
            setButtonDisable(false)
        } else {
            setButtonDisable(true)
        }
    },[userInfo])

    useEffect(()=> {
        if(props.token !== '') {
            navigate('/')
        }
    },[props.token, navigate])

    const onClick = () => {
        setButtonTxt(
            <div id="loadingbutton">
                <Spinner animation="border"/>
                로딩중...
            </div>
        )
        client.post(serverURL+'/user/login', userInfo, {
            'headers': {
                "Content-Type": 'application/x-www-form-urlencoded'
            }})
            .then(res => {
                props.logIn(res.data.access_token)
                //navigate('/')
            })
            .catch(err => {
                setButtonTxt('로그인')
                try {
                    if(err.response.status === 401) {
                        setMsg('아이디 혹은 비밀번호가 잘못되었습니다.')
                    } else {
                        setMsg('오류가 발생했습니다. 다시 시도해주세요')
                    }
                }
                catch {
                    setMsg('오류가 발생하였습니다. 지속될 시 관리자에게 문의해 주세요.')
                }
                
            })
    }


    return (
        <div>
            <Container className="panel" id="logincontainer">
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            아이디
                        </Form.Label>
                        <Col sm>
                            <Form.Control placeholder="UserID" name="username" onChange={onChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            비밀번호
                        </Form.Label>
                        <Col sm>
                            <Form.Control type="password" placeholder="Password" name="password" onChange={onChange}/>
                        </Col>
                    </Form.Group>
                    <br/>
                    <label id="alertingmsg">{msg}</label>
                    <div className="d-grid gap-1">
                        <Button variant="secondary" onClick={onClick} disabled={buttonDisable}>
                            {buttonTxt}
                        </Button>
                    </div>
                    <Link to='/register'>
                        <label id="gotoregister">계정이 없다면 회원가입하기-＞</label>
                    </Link>
                </Form>
            </Container>
        </div>
    );
}