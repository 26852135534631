// ApplicantInfo.js

import React from 'react';
import styled from 'styled-components';

// 스타일드 컴포넌트를 사용하여 스타일을 정의합니다.
const ApplicantContainer = styled.div.attrs((props) => ({
    id: props.containerId || 'defaultContainerId',
  }))`
  border: 1px solid #ccc;
  padding: 16px;
  margin: 16px;
  max-width: 300px;
`;

const ApplicantTitle = styled.h2`
  color: #333;
  font-size: 1.5em;
  margin-bottom: 8px;
`;

// ApplicantInfo 컴포넌트 정의
export const AssignCard = ({ title, children, containerId }) => {
  return (
    <ApplicantContainer containerId={containerId}>
      <ApplicantTitle>{title}</ApplicantTitle>
      {children}
    </ApplicantContainer>
  );
};
