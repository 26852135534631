import { Main } from "./pages/Main";
import { Login } from "./pages/Account/Login";
import { Register } from "./pages/Account/Register";
import { Notfound } from "./pages/404";
import { Navi } from "./pages/Nav";
import { Clubs } from './pages/Club/Clubs'
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Assign } from "./pages/User/Assign";
import { Club } from "./pages/Club/Club";
import { AboutUs } from "./pages/AboutUs/AboutUs";
// import { Chat } from "./pages/Chat";
function App() {
  const [userInfo, setUserInfo] = useState({})
  const [token, setToken] = useState(window.localStorage.getItem('access_token'))


  const logOut = () => {
    window.localStorage.setItem('access_token', '')
    setToken('')
  }

  const logIn = (tkn) => {
    window.localStorage.setItem('access_token',tkn)
    setToken(tkn)
  }

  useEffect(() => {
    if (token) {
      window.localStorage.setItem("access_token",token);
      let jsonPayload = {"id":"", "name":"","role":"", "club_name":""}
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      jsonPayload = JSON.parse(jsonPayload);
      setUserInfo(jsonPayload)
    } else {
      logOut()
    }
  }, [token]); 

  return (
    <div className="App">
      <Router>
        <Navi token={token} logOut={logOut} userInfo={userInfo}/>
        <Routes>
          <Route path="/" exact={true} element={<Main token={token} userInfo={userInfo} logOut={logOut}/>}/>
          <Route path="/login" element={<Login logIn={logIn} token={token}/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/clubs" element={<Clubs token={token} logOut={logOut}/>}/>
          <Route path="/club/:id" element={<Club token={token} logOut={logOut}/>}/>
          <Route path="/assign/:id" element={<Assign token={token} userInfo={userInfo} logOut={logOut}/>}/>
          <Route path="/aboutus" element={<AboutUs/>}/>
          {/* <Route path="/chat/*" element={<Chat token={token} userInfo={userInfo} logOut={logOut}/>}/> */}
          <Route path="*" element={<Notfound/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;