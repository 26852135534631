// FullPageScrollPage.js

import React from 'react';
import {SectionsContainer, Section} from 'react-fullpage';
import { Developers } from './Developers';
import { SpecialThanks } from './SpecialThanks';
import { FullPersonWrapper } from '../../components/Person';

export const AboutUs = () => {
    let options = {
        anchors: ['sectionOne', 'sectionTwo'],
    };
  return (
    <FullPersonWrapper>
      <SectionsContainer {...options}>
        <Section><Developers/></Section>
        <Section><SpecialThanks/></Section>
      </SectionsContainer>
    </FullPersonWrapper>
    
  );
};