// SimpleButton.js
import styled from 'styled-components';

export const SimpleButtonWrapper = styled.button`
  background-color: #20a494;
  height: 30px;
  color: #fff;
  font-size: 14px;
  padding: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* 그림자 트랜지션 추가 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 그림자 추가 */

  &:hover {
    background-color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 호버 시 그림자 강화 */
  }
`;


export const DownloadButtonWrapper = styled.button`
  background-color: #20a494;
  color: #fff;
  height: 30px;
  padding: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* 그림자 트랜지션 추가 */

  &:hover {
    background-color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 호버 시 그림자 강화 */
  }

  /* 아이콘 스타일 */
  svg {
    margin-right: 5px;
  }
`;
