import { useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import axios from "axios"
import Form from "react-bootstrap/Form"; 
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Spinner } from "react-bootstrap";
import { RegisterModal } from "./RegisterModal";
import '../../style/login.css'
import { serverURL } from "../../serverURL";

export function Register() {
    const client = axios.create()
    const navigate = useNavigate();
    const [buttonTxt, setButtonTxt] = useState('회원가입')
    const [btnDisable, setBtnDisable] = useState(true)
    const [check, setCheck] = useState(false)
    const [show, setShow] = useState(false)
    const [msg, setMsg] = useState('')
    const [userInfo, setUserInfo] = useState({
        'intranet_id':'',
        'intranet_password':'',
        'name':'',
        'username':'',
        'password1':'',
        'password2':'',
        'email1':'',
        'email2':'',
        'id':0
    })
    
    let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

    const handleClose = () => setShow(false);

    useEffect(()=> {
        if(userInfo.intranet_id === '' || userInfo.intranet_password === '' || userInfo.name === '' || userInfo.username === '' || userInfo.password1 === '' || userInfo.password2 === '' || userInfo.email1 === '' || userInfo.email2 === '') {
            setBtnDisable(true)
        } else {
            setBtnDisable(false)
        }
    }, [userInfo])

    const onCheck = (e) => {
        if (check) {
            setCheck(false)
        } else {
            setCheck(true)
            setShow(true)
        }
    }

    const onChange = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name] : e.target.value
        })
    }


    const onClick = () => {
        if (userInfo.email1 !== userInfo.email2) {
            setMsg('이메일과 이메일 확인이 일치하지 않습니다')
        } else if(userInfo.password1 !== userInfo.password2) {
            setMsg('비밀번호와 비밀번호 확인이 일치하지 않습니다')
        } else if (regex.test(userInfo.email1) === false) {
            setMsg('유효하지 않은 이메일 입니다.')
        } else {
            setButtonTxt(
                <div id="loadingbutton">
                    <Spinner  animation="border"/>
                    로딩중...
                </div>
            )
            client.post(serverURL+'/user/create', userInfo)
            .then(res => {
                navigate('/login')
            })
            .catch(err => {
                if(err.response.status === 409) {
                    setMsg('이미 존재하는 사용자 입니다. 본인이 가입하지 않았다면 운영자에게 문의하세요')
                } else if(err.response.status === 422){
                    setMsg('이미 존재하는 사용자 입니다. 본인이 가입하지 않았다면 운영자에게 문의하세요')
                }
            })
        }
    }
    
    return(
        <div>
            <Container className="panel" id="registercontainer">
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            인트라넷 아이디
                        </Form.Label>
                        <Col sm>
                            <Form.Control placeholder="인트라넷 아이디를 입력해 주십시오" name="intranet_id" onChange={onChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            인트라넷 비밀번호
                        </Form.Label>
                        <Col sm>
                            <Form.Control type="password" placeholder="인트라넷 비밀번호를 입력해 주십시오" name="intranet_password" onChange={onChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            이름
                        </Form.Label>
                        <Col sm>
                            <Form.Control placeholder="이름을 입력해 주십시오" name="name" onChange={onChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            아이디
                        </Form.Label>
                        <Col sm>
                            <Form.Control placeholder="아이디를 입력해 주십시오" name="username" onChange={onChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            비밀번호
                        </Form.Label>
                        <Col sm>
                            <Form.Control type="password" placeholder="비밀번호를 입력해 주십시오" name="password1" onChange={onChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            비밀번호 확인
                        </Form.Label>
                        <Col sm>
                            <Form.Control type="password" placeholder="비밀번호를 확인해 주십시오" name="password2" onChange={onChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            이메일(안내용)
                        </Form.Label>
                        <Col sm>
                            <Form.Control placeholder="안내를 받을 이메일을 입력해 주십시오" name="email1" onChange={onChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            이메일 확인
                        </Form.Label>
                        <Col sm>
                            <Form.Control placeholder="이메일을 확인해 주십시오" name="email2" onChange={onChange}/>
                        </Col>
                    </Form.Group>
                    <input type="checkbox" id="policycheck" onChange={onCheck} checked={check}/>개인정보 수집 및 이용약관에 동의합니다
                    <br/>
                    <label id="alertingmsg">{msg}</label>
                    <div className="d-grid gap-1">
                        <Button variant="secondary" onClick={onClick} disabled={btnDisable}>
                            {buttonTxt}
                        </Button>
                    </div>
                    <Link to='/login'>
                        <label id="gotoregister">이미 계정이 있다면 로그인하기-＞</label>
                    </Link>
                </Form>
            </Container>
            <RegisterModal show={show} handleClose={handleClose}/>
        </div>
        
    )
}