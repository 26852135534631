import React from 'react';
import developer1Image from '../../img/specialThanks1.png'; // 이미지 파일의 실제 경로에 따라 수정
import developer2Image from '../../img/specialThanks2.png'; // 이미지 파일의 실제 경로에 따라 수정
import { useScrollFadeIn } from '../../hooks/useScrollFadeIn';
import { PersonDescription, PersonImage, PersonName, PersonInfo, PersonWrapper, PersonTitle } from '../../components/Person';


export const SpecialThanks = () => {
    const animatedItem = {
        0: useScrollFadeIn('up', 0.6, 0.5),
        1: useScrollFadeIn('left', 0.6, 0.8),
        2: useScrollFadeIn('right', 0.6, 1.1),
    };
  return (
    <div>
        <PersonTitle {...animatedItem[0]}>Special Thanks</PersonTitle>
        <PersonWrapper>
            {/* 첫 번째 개발자 */}
            <PersonInfo {...animatedItem[1]}>
                <PersonImage src={developer1Image} alt="Developer 1" />
                <PersonName>정윤희</PersonName>
                <PersonDescription>
                    Computer Science teacher of Hana Academy Seoul.
                </PersonDescription>
            </PersonInfo>

            {/* 두 번째 개발자 */}
            <PersonInfo {...animatedItem[2]}>
                <PersonImage src={developer2Image} alt="Developer 2" />
                <PersonName>권동한</PersonName>
                <PersonDescription>
                    Student of Hana Academy Seoul 13th.
                </PersonDescription>
            </PersonInfo>
        </PersonWrapper>
    </div>
    
  );
};
