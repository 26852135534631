import { useEffect, useState } from "react"
import { AssignModal } from "./AssignModal";
import { Link } from "react-router-dom";
import axios from "axios"
import Table from 'react-bootstrap/Table';
import '../../style/main.css'
import { serverURL } from "../../serverURL";
import { AssignCard } from "./AssignCard";
import styled from "styled-components";

export function UserMain(props) {
    const client = axios.create()
    const clubKey = ["first_club1", "first_club2", "second_club1", "second_club2", "third_club1", "third_club2", "final_club"]
    const [club, setClub] = useState({"first_club1":"loading...", "first_club2":"loading...", "second_club1":"loading...", "second_club2":"loading...", "third_club1":"loading...", "third_club2":"loading...", "final_club":"loading..."})
    const [checkButton, setCheckButton] = useState([])
    const [show, setShow] = useState(false);
    const [selectKey, setSelectKey] = useState('')
    const [order, setOrder] = useState(0)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)

const getClub = () => {
  client.get(serverURL+'/user/clubs', {
          'headers': {
              "Authorization": `Bearer ${props.token}`
          }})
        .then(res=>{
          setClub(res.data)
        })
        .catch(err=>{
            if (err.response.status === 401) {
              props.logOut()
            }
        })
}

useEffect(() => {
  getClub()
},[])




useEffect(()=>{
  if(club.length !== 0) {
    for (let i=0; i<7; i++) {
      if(club[clubKey[i]] === 'null') {
        setClub({
          ...club,
          [clubKey[i]] : '-'
        })
      }
    }

  }
},[club])

const onClickClubAssign = (e) => {//신청 내용 확인
  setSelectKey(e.target.parentNode.id)
  setOrder(parseInt(e.target.parentNode.parentNode.parentNode.id.slice(6,7)))
  handleShow()
}

const RefreshButtonWrapper = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const StatusButton = styled.button`
  background-color: ${(props) => (props.applied ? '#4CAF50' : '#f44336')};
  color: #fff;
  padding: 8px;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.applied ? '#45a049' : '#d32f2f')};
  }

  &:active {
    background-color: ${(props) => (props.applied ? '#3e8e41' : '#b71c1c')}; /* 클릭 시의 색상 변경 */
  }
`;

useEffect(()=>{
  if(club.length !== 0){
    const temp = []
    for(let i=0; i<7; i++) {
      if(club[clubKey[i]] === '-'){
        temp[i] = <Link to={`/assign/${Math.floor(i / 2)+1}${(i % 2 === 0) ? '1' : '2'}`}><StatusButton applied={false}>지원하기</StatusButton></Link>
      } else {
        temp[i] = <StatusButton applied={true} onClick={onClickClubAssign}>지원 현황 확인</StatusButton>
      }
    }
    setCheckButton(temp)
  }
}, [club])

    return(
        <div id='mainwrapper'>
          {/* <RefreshButtonWrapper onClick={getClub}>새로고침</RefreshButtonWrapper> */}
          <Table responsive="sm">
            <thead>
              <tr>
                <th></th>
                <th>지원 동아리-1</th>
                <th>지원 동아리-2</th>
              </tr>
            </thead>
            <tbody>
              <tr id="assign1">
                <td>1차</td>
                {/* {<td id="first_club1">{club.first_club1}{checkButton[0]}</td>} */}
                <td><AssignCard title={club.first_club1} applied={club.first_club1 === '-' ? false : true} containerId="first_club1">{checkButton[0]}</AssignCard></td>
                <td><AssignCard title={club.first_club2} applied={club.first_club2 === '-' ? false : true} containerId="first_club2">{checkButton[1]}</AssignCard></td>
              </tr>
              <tr id="assign2">
                <td>2차</td>
                <td><AssignCard title={club.second_club1} applied={club.second_club1 === '-' ? false : true} containerId="second_club1">{checkButton[2]}</AssignCard></td>
                <td><AssignCard title={club.second_club2} applied={club.second_club2 === '-' ? false : true} containerId="second_club2">{checkButton[3]}</AssignCard></td>
              </tr>
              <tr id="assign3">
                <td>3차</td>
                <td><AssignCard title={club.third_club1} applied={club.third_club1 === '-' ? false : true} containerId="third_club1">{checkButton[4]}</AssignCard></td>
                <td><AssignCard title={club.third_club2} applied={club.third_club2 === '-' ? false : true} containerId="third_club2">{checkButton[5]}</AssignCard></td>
              </tr>
            </tbody>
          </Table>
          <AssignModal handleClose={handleClose} handleShow={handleShow} show={show} clubName={club[selectKey]} order={order} num={parseInt(selectKey[selectKey.length-1])} token={props.token} userInfo={props.userInfo} getClub={getClub} logOut={props.logOut}/>
        </div>
      )
}