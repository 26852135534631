import { useEffect } from "react"
import { useNavigate } from "react-router-dom"


export function Notfound() {
    const navigate = useNavigate()
    useEffect(() => {
        navigate("/")
    },[])
    return(
        <div>
            <h1>ERROR 404 NOT FOUND</h1>
        </div>
    )
}