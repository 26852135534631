import Table from 'react-bootstrap/Table';
import '../style/main.css'
import { UserMain } from "./User/UserMain";
import { LeaderMain } from './Leader/LeaderMain';
import { MemberMain } from './Member/MemberMain';
export function Main(props) {
    if (props.userInfo.role === 'Leader') {
      return(
        <LeaderMain token={props.token} userInfo={props.userInfo} logOut={props.logOut}/>
      )
    } else if (props.userInfo.role === 'Member') {
      return(
        <MemberMain token={props.token} userInfo={props.userInfo} logOut={props.logOut}/>
      )
    } else {
      return(
        <UserMain token={props.token} userInfo={props.userInfo} logOut={props.logOut}/>
      )
    }
}