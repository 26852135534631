import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { serverURL } from "../../serverURL";
import { Spinner, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import '../../style/assignmodal.css'
import { useNavigate } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import { stringify } from 'qs';
export function AssignModal(props) {
    const client = axios.create()
    const navigate = useNavigate()
    const [cancelBtnTxt, setCancelBtnTxt] = useState('지원 취소')
    const [checkBtnTxt, setCheckBtnTxt] = useState('지원서 확인')
    const [reBtnTxt, setReBtnTxt] = useState('지원서 재제출')
    const [file, setFile] = useState(null)
    const [date_start, setDate_start] = useState('')
    const [date_end, setDate_end] = useState('')
    const [assignInfo, setAssignInfo] = useState({
        "club_name": "",
        "loc": "",
        "got": "",
        "date": "",
        "message": ""
    })


    useEffect(()=> {
        if(props.token !== '' && props.show === true) {
            client.get(serverURL+'/user/club/status', {
                'params': {'order': props.order, 'num': props.num},
                'headers': {
                    "Authorization": `Bearer ${props.token}`
                }})
            .then(res=>{
                setAssignInfo(res.data)
            })
            .catch(err=>{
                if (err.response.status === 401) {
                    props.logOut()
                  }
            })
        }
    },[props.show])

    useEffect(()=> {
        if (assignInfo.date_start && assignInfo.date_end) {
            let start_temp = new Date(parseInt(assignInfo.date_start.slice(0,4))-1, parseInt(assignInfo.date_start.slice(5,7)), parseInt(assignInfo.date_start.slice(8,10)), parseInt(assignInfo.date_start.slice(11,13)), parseInt(assignInfo.date_start.slice(14,16)))
            
            if (isNaN(start_temp.getDate())) {
                setDate_start('미정')
                setDate_end('미정')
            } else {
                setDate_start(start_temp.getFullYear() + '년 ' + `${start_temp.getMonth() === 0 ? '12' : start_temp.getMonth()}` + '월 ' + start_temp.getDate() + '일 ' + start_temp.getHours() + '시 ' + start_temp.getMinutes() + '분')
                let end_temp = new Date(parseInt(assignInfo.date_end.slice(0,4))-1, parseInt(assignInfo.date_end.slice(5,7)), parseInt(assignInfo.date_end.slice(8,10)), parseInt(assignInfo.date_end.slice(11,13)), parseInt(assignInfo.date_end.slice(14,16)))
                setDate_end(end_temp.getFullYear() + '년 ' + `${end_temp.getMonth() === 0 ? '12' : end_temp.getMonth()}` + '월 ' + end_temp.getDate() + '일 ' + end_temp.getHours() + '시 ' + end_temp.getMinutes() + '분')
            }
        } else {
            setDate_start('미정')
            setDate_end('미정')
        }
        // 이걸 유지보수할 후배님이 계시다면 음... 죄송합니다
    },[assignInfo.date_start, assignInfo.date_end])




    const watchMyAssignDocument = () => {
        setCheckBtnTxt(<div>
            <Spinner animation='border'/>
            로딩중...
        </div>)
        client.get(serverURL+'/club/download', {
            'params' : {
                'id':props.userInfo.id,
                'club_name':assignInfo.club_name,
                'order':props.order,
                'num':props.num
            },
            'headers': {
                "Authorization": `Bearer ${props.token}`
            },
            'responseType' : 'blob'
        })
        .then(res=>{
            setCheckBtnTxt('지원서 확인')
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${props.userInfo.name}_${assignInfo.club_name}_${props.order}차-${props.num}_지원서.pdf`;
            a.click();
        })
        .catch(err=>{
            console.log(err)
            alert('파일을 불러오는데에 오류가 발생했습니다. 지원서를 제출하지 않았을 수 있습니다.')
            navigate('/')
            setCheckBtnTxt('지원서 확인')
        })
    }



    const cancelAssign = () => {
        setCancelBtnTxt(<div>
            <Spinner animation='border'/>
            취소중...
        </div>)
        const isCancel = window.confirm('정말 지원을 취소하시겠습니까?')
        if (isCancel) {
            axios.post(serverURL+'/club/cancel', {
                'id':props.userInfo.id,
                'order':props.order,
                'num':props.num
            }, {
                'headers': {
                    "Authorization": `Bearer ${props.token}`
                }
            })
            .then(res=>{
                setCancelBtnTxt('취소 완료')
                props.getClub()
                props.handleClose()
                setCancelBtnTxt('지원 취소')
            })
            .catch(err=>{
                if(err.response.status === 422) {
                    console.log(err.response.detail)
                }
            })
        } else {
            setCancelBtnTxt('지원 취소')
        }
    }



    useEffect(()=>{
        if (file !== null){
            setReBtnTxt(<dev>
                <Spinner animation='border'/>
                로딩중...
            </dev>)
            const formData = new FormData();
            formData.append("file", file);
            client.post(`${serverURL}/club/upload${props.num}`, formData, {
                'headers': {
                "Authorization": `Bearer ${props.token}`
            }})
            .then(res=>{
                alert('지원서 수정 완료')
                setReBtnTxt('지원서 재제출')
            })
            .catch(err=>{
                alert(err.response.data.detail)
                setReBtnTxt('지원서 재제출')
            })
        }
    },[file])




  return (
      <Modal show={props.show} onHide={props.handleClose} size="lg" id='assignModal'>
        <Modal.Header closeButton>
          <Modal.Title>{props.clubName} 지원 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table>
                <thead>
                    <tr>
                        <td>동아리 이름</td>
                        <td>{assignInfo.club_name}</td>
                    </tr>
                    <tr>
                        <td>면접 장소</td>
                        <td>{assignInfo.loc}</td>
                    </tr>
                    <tr>
                        <td>면접 시작</td>
                        <td>{date_start}</td>
                    </tr>
                    <tr>
                        <td>면접 종료</td>
                        <td>{date_end}</td>
                    </tr>
                    <tr>
                        <td>합격 여부</td>
                        <td>{assignInfo.got === '미정' ? '미정' : assignInfo.got ? '합격' : '불합격'}</td>
                    </tr>
                </thead>
            </Table>
            {assignInfo.got === "미정" ? <div/> : <div><h5>전달사항</h5><div>{assignInfo.message}</div></div>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant='danger' id='assigncancelbutton' onClick={cancelAssign}>
                {cancelBtnTxt}
            </Button>
            <Button variant='info' onClick={watchMyAssignDocument}>
                {checkBtnTxt}
            </Button>
            <FileUploader
                name="file"
                types={['PDF']}
                multiple={false}
                onDrop={(file) =>{setFile(file)}}
                onSelect={(file) =>{setFile(file)}}
                onChange={(file) =>{setFile(file)}}
                children={
                    <Button variant='warning'>
                        {reBtnTxt}
                    </Button>
                }
            />
            
          <Button variant="secondary" onClick={props.handleClose}>
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
  );
}