import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import '../../style/assignmodal.css'
import { serverURL } from "../../serverURL";
export function RegisterModal(props) {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

  const handleScroll = (event) => {
    const div = event.target;
    if (div.scrollHeight - div.scrollTop === div.clientHeight) {
      setIsScrolledToBottom(false);
    } else {
      setIsScrolledToBottom(true);
    }
  };

  return (
      <Modal show={props.show} size="lg" id='assignModal'>
        <Modal.Header>
          <Modal.Title>이용약관 및 개인정보처리방침</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div id='policycontainer' onScroll={handleScroll}>
              <p>"하나고등학교 동아리 입시 시스템"은 회원님들의 권익을 보호하고, 회원님들의 개인정보 보호에 만전을 기하고자, 관계법령의 규정을 반영하여 약관을 제정하였습니다.<br/><span>개정된 약관은 2023년 10월 23일부터 변경약관을 적용하고 있습니다.</span></p>
              <h4>제 1장 총칙</h4>
              <h5>제 1조(목적)</h5>
              <p>
              이 약관은 "하나고등학교 동아리 입시 시스템"(이하 “서비스”)에서 제공하는 하나고등학교 동아리의 원서접수 및 입시 지원 서비스 이용과 관련하여
              “서비스”와 이용자 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
              </p>
              <h5>제 2조(정의)</h5>
              <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
              <ol>
                <li>
                  “사이트”라 함은 컴퓨터 등 정보통신설비를 통하여 이용자가 정보 또는 용역을 사용하고 거래할 수 있도록 설정한 가상의
                  영업장 또는 웹사이트를 말하며, 아래의 도메인 주소를 사용하는 모든 웹사이트를 포함합니다. 아울러 전자상거래를 운영하는 사업자의
                  의미로도 사용합니다.
                  * 도메인 : hees.academy
                </li>
                <br/>
                <li>
                  “이용자”라 함은 "사이트"에 접속하여 이 약관에 따라 “서비스”를 받는 회원 및 비회원을 말합니다.
                </li>
                <br/>
                <li>
                  “회원”이라 함은 “서비스”에 개인정보를 제공하여 회원등록을 한 자로서, “서비스”와 서비스 이용계약을 체결하고 회원 아이디(ID)를 부여 받아
                  서비스를 계속적으로 이용할 수 있는 자를 말합니다.
                </li>
                <br/>
                <li>
                  “회원 아이디(이하 “ID”)”라 함은 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 “서비스”가 승인하는 문자 또는
                  숫자의 조합을 말합니다.
                </li>
                <br/>
                <li>
                  “비밀번호(Password)”라 함은 이용자가 등록회원과 동일인인지 신원을 확인하고, 회원의 통신상 개인정보를 보호하기 위하여
                  회원이 정한 문자와 숫자의 조합을 말합니다.
                </li>
                <br/>
                <li>
                  “회원탈퇴”라 함은 회원이 이용계약을 종료시키는 행위를 말합니다.
                </li>
                <br/>
                <li>
                  “컨텐츠(Contents)”라 함은 사이트에서 제공하는 정보, 인터넷 원서접수, 기타 관련정보 및 프로그램을 의미합니다.
                </li>
                <br/>
                <li>"동아리장"은 하나고등학교의 동아리에 있어 행정상 동아리의 대표자를 의미합니다.</li>
              </ol>
              <h5>제 3조 (약관의 명시와 개정)</h5>
              <ol>
                <li>
                  이 약관은 그 내용을 “서비스”의 사이트에 게시하거나 이메일 등 기타의 방법으로 회원에게 공지함으로써 효력이 발생합니다.
                </li>
                <br/>
                <li>
                  서비스 이용시 화면에서 제시되는 경고 메시지의 효력은 본 약관의 효력과 동일합니다.
                </li>
                <br/>
                <li>
                  “서비스”는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보 보호 이용에 관한 법률 기타 관련 법령을
                  위배하지 않는 범위 내에서, 사정 변경의 경우와 영업상 중요 사유가 있을 때 약관을 변경할 수 있으며, 변경된 약관은 ②항과 같은
                  방법으로 효력을 발생합니다.
                </li>
                <br/>
                <li>
                  이 약관에 동의하는 것은 정기적으로 사이트를 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한
                  정보를 알지 못해 발생하는 이용자의 피해는 “서비스”가 책임지지 않습니다.
                </li>
                <br/>
                <li>
                  회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이내에
                  거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
                </li>
              </ol>
              <h5>제4조(약관 외 준칙)</h5>
              <ol>
                <li>
                  이 약관은 "서비스”가 제공하는 개별서비스에 관한 이용안내(이하 “서비스별 안내”라 합니다)와 함께 적용합니다.
                </li>
                <br/>
                <li>
                  이 약관에 명시되지 않은 사항에 대해서는 전기통신사업 기본법 및 기타 관련법령, 서비스별 안내의 규정에 의합니다.
                </li>
              </ol>
              <h4>제2장 서비스 이용 계약</h4>
              <h5>제5조(이용 계약의 성립)</h5>
              <ol>
                <li>
                이용 계약은 서비스 이용 희망자가 본 이용 약관에 대해 동의한다는 의사표시와 이용 신청에 대한 “서비스”의 승낙으로 성립됩니다.
                </li>
                <br/>
                <li>
                본 이용약관에 대한 동의는 이용 신청 당시 사이트의 “위 약관을 숙지하였으며 이에 동의합니다.” 버튼을 누름으로써 그 의사를 표시합니다.
                </li>
              </ol>
              <h5>제6조(회원가입 및 승낙)</h5>
              <ol>
                <li>이용자가 회원에 가입하여 사이트의 서비스를 이용하고자 하는 경우, 이용자는 사이트에서 요청하는 개인 신상정보를 제공해야 합니다.</li>
                <br/>
                <li>이용자의 사이트의 서비스 이용신청에 대하여 사이트가 승낙한 경우, 사이트는 회원 ID/Password와 기타 사이트가 필요하다고 인정하는 내용을 이용자에게 통지합니다.</li>
                <br/>
                <li>"서비스”는 다음 각 호에 해당하는 이용 신청에 대하여는 이를 승낙하지 아니합니다. </li>
                <ol>
                  <li>본인 실명이 아니거나 다른 사람의 명의를 사용하여 신청하였을 때</li>
                  <li>서비스 이용 계약 신청서의 내용을 허위로 기재하였을 때</li>
                  <li>사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때</li>
                  <li>부정한 용도로 본 서비스를 이용하고자 하는 경우</li>
                  <li>기타 이용신청자의 귀책사유로 이용승낙이 곤란한 경우</li>
                </ol>
                <br/>
                <li>“서비스”는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다. </li>
                <ol>
                  <li>"서비스”가 설비의 여유가 없는 경우</li>
                  <li>“서비스”의 기술상 지장이 있는 경우</li>
                  <li>기타 “서비스”의 귀책사유로 이용승낙이 곤란한 경우</li>
                </ol>
              </ol>
              <h4>제 7조 (서비스 이용)</h4>
              <ol>
                <li>“서비스”의 서비스 이용은 사이트의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</li>
                <br/>
                <li>“서비스”는 서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다.</li>
              </ol>
              <h4>제3장 서비스 제공 및 변경</h4>
              <h5>제8조(서비스 내용)</h5>
              <p>"서비스"가 "이용자"에게 제공하는 서비스는 다음과 같습니다.</p>
              <ol>
                <li>하나고등학교 신입생의 동아리 신규 지원 혹은 재학생의 동아리 편입에 대하여 지원서 제출과 입시 정보 확인</li>
                <br/>
                <li>동아리의 지원자들의 지원에 있어 "동아리장"에게 합불 여부 결정 및 면접 장소와 시간 안내에 대한 기능 제공</li>
                <br/>
                <li>"이용자" 모두에게 있어 하나고등학교의 동아리에 관한 정보 열람 기능 제공</li>
                <br/>
                <li>"이용자" 중 동아리의 지원자와 "동아리장"간에 채팅(실시간 텍스트를 통한 정보 교류) 기능 제공</li>
                <br/>
                <li>"동아리장"의 동아리에 대한 소개글 작성 서비스 제공</li>
              </ol>
              <h4>제4장 개인정보처리방침</h4>
              <h5>제9조(회원의 개인정보 보호)</h5>
              <ol>
                <li>“서비스”는 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.</li>
                <br/>
                <li>회원이 “서비스”의 본 약관에 따라 이용신청을 함으로써, 회원이 회원가입신청서에 기재한 회원 정보를 수집, 이용하는 것에 대해 동의하는 것으로 간주합니다.</li>
                <br/>
                <li>수집된 개인정보는 이용자의 동의가 없는 한 당해 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 “서비스”가 집니다. 다만, 다음의 경우에는 예외로 합니다. </li>
                <ol>
                  <li>통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</li>
                  <li>도용방지를 위하여 본인 확인에 필요한 경우</li>
                  <li>법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</li>
                </ol>
                <br/>
                <li>"서비스”는 본조 제①항과 제③항에 의해 이용자의 동의를 받아야 하는 경우에는 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항 등을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</li>
                <br/>
                <li>
                  이용자는 언제든지 “서비스”가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “서비스”는 이에 대해 지체 없이
                  필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “서비스”는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다
                </li>
                <br/>
                <li>“서비스”는 이용자 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.</li>
                <br/>
                <li>“서비스” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</li>
              </ol>
              <h5>제10조(개인정보 수집, 이용 목적, 수집하는 개인정보의 항목 및 수집방법)</h5>
              <ol>
                <li>“서비스”에서는 아래와 같은 목적과 방법으로 개인정보를 수집하고 이용하고 있습니다. </li>
                <ol>
                  <li>회원관리</li>
                  <ol>
                    <li>필수항목 : 아이디, 비밀번호, 이름, 생년월일, 성별, 이메일주소,</li>
                    <li>수집방법 : 회원가입</li>
                  </ol>
                  <li>원활한 서비스 제공</li>
                  <ol>
                    <li>필수항목 : 접속 로그, 쿠키, 접속 IP정보</li>
                    <li>수집방법 : 생성정보 수집 툴을 통한 수집</li>
                  </ol>
                </ol>
              </ol>
              <h5>제11조(개인정보의 보유 및 이용 기간)</h5>
              <ol>
                <li>원칙적으로 "이용자"에 대한 개인정보는 "이용자"가 하나고등학교에 대하여 졸업 시에 파기됩니다</li>
                <br/>
                <li>동아리에 관한 행정적 기록 혹은 분쟁 해결 등에 관한 목적으로 위 항에 해당하더라도 파기가 보류될 수 있습니다</li>
                <br/>
                <li>"이용자"의 요구에 의해서라면 관련 법령에 의거한 경우가 아니라면 지체없이 해당 "이용자"의 정보는 파기됩니다</li>
                <br/>
                <li>다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</li>
                <ol>
                  <li>서비스 이용 및 기관 수사 협조 등을 위해 필요한 경우 아래와 같이 회원 정보를 보관합니다.</li>
                  <ol>
                    <li>보존항목 : 서비스 이용기록, 접속로그, 쿠키, 접속IP 정보</li>
                    <li>보존근거 : 서비스 혼선 방지, 수사기관에 의한 협조</li>
                    <li>보존기간 : 3개월</li>
                  </ol>
                  <li>관계법령의 규정에 의하여 보존할 필요가 있는 경우 아래와 같이 관계법령(전자상거래등에서의소비자보호에관한법률)에서 정한 일정한 기간 동안 회원 정보를 보관합니다.</li>
                  <ol>
                    <li>표시, 광고에 관한 기록 : 6개월</li>
                    <li>계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)</li>
                    <li>대금결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)</li>
                    <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)</li>
                  </ol>
                </ol>
              </ol>
              <h5>제12조(회원의 ID 및 비밀번호에 대한 의무)</h5>
              <ol>
                <li>제9조, 제10조, 제11조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</li>
                <br/>
                <li>회원은 자신의 ID 및 비밀번호를 제 3자에게 이용하게 해서는 안 되며, 그로 인한 모든 책임은 회원에게 있습니다.</li>
                <br/>
                <li>
                  회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "서비스”에 통보하고 "서비스”의 안내가
                  있는 경우 그에 따라야 합니다. 만약 회원이 위 사실을 인지하고도 "서비스”에 대한 통보를 지체함으로써 발생한 손해에 대해서 "서비스”는
                  배상할 의무가 없습니다.
                </li>
              </ol>
              <h4>제5장 서비스와 관련한 권한, 의무 관계</h4>
              <h5>제13조(“서비스”의 의무)</h5>
              <ol>
                <li>“서비스”는 회원이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록 하여야 합니다.</li>
                <br/>
                <li>“서비스”는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생겼을 때에는 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구합니다.</li>
                <br/>
                <li>“서비스”는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보 보호정책을 공시하고 준수합니다.</li>
                <br/>
                <li>“서비스”는 이용자가 제기하는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에 적절한 절차를 거쳐 즉시 이를 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 이용자에게 그 사유와 처리일정을 통보하여야 합니다.</li>
              </ol>
              <h5>제14조(회원의 의무)</h5>
              <ol>
                <li>회원은 다음 각 호의 행위를 해서는 안 됩니다. </li>
                <ol>
                  <li>회원가입 신청 또는 변경 시 허위내용을 등록하는 행위</li>
                  <li>“서비스”에 게시된 정보를 변경하는 행위</li>
                  <li>“서비스” 또는 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위</li>
                  <li>다른 회원의 ID를 도용하는 행위</li>
                  <li>관련 법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램 등)를 전송 또는 게시하는 행위</li>
                  <li>“서비스”의 관리자나 본 사이트 서비스의 관리자를 가장, 사칭하거나 타인의 명의를 도용하여 글을 게시하거나 채팅을 발송하는 행위</li>
                  <li>컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 채팅으로 발송하는 행위</li>
                  <li>스토킹(stalking) 등 다른 회원을 괴롭히는 행위</li>
                  <li>다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위</li>
                  <li>“서비스”가 정한 약관 기타 서비스 이용에 관한 규정을 위반하는 행위</li>
                  <li>외설 또는 폭력적인 메시지ㆍ화상ㆍ음성 기타 미풍양속에 반하는 정보를 공개 또는 게시하는 행위</li>
                  <li>사실관계를 왜곡하는 정보제공 등 기타 “서비스”가 부적절하다고 판단하는 행위</li>
                </ol>
                <br/>
                <li>회원이 개인의 정보를 허위 또는 잘못 기재하여 생기는 불이익에 대한 책임은 회원 본인에게 있으며, 이에 대하여 “서비스”는 책임을 지지 않습니다.</li>
                <br/>
                <li>제1항에 해당하는 행위를 한 회원이 있을 경우 “서비스”는 본 약관 제 12조에서 정한 바에 따라 회원의 회원자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.</li>
                <li>회원은 그 귀책사유로 인하여 “서비스”나 다른 회원이 입은 손해를 배상할 책임이 있습니다.</li>
              </ol>
              <h5>제15조(게시물 또는 내용물의 삭제)</h5>
                <p>“서비스”는 이용자가 게시하거나 등록한 내용이 제 28조의 규정에 위반되거나 “서비스”에서 정한 게시기간을 초과하는 경우 게시를 행한 자로부터 동의를 받지 않고도 이를 삭제할 수 있습니다. 단, “서비스”는 이러한 정보에 대하여 삭제 등 어떠한 조치의 의무를 지지 않습니다.</p>
              <h5>제16조(저작권의 귀속 및 권리, 의무)</h5>
              <ol>
                <li>“서비스”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “서비스”에 귀속합니다.</li>
                <li>이용자는 본 사이트의 서비스를 이용함으로써 얻은 정보를 “서비스”의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.</li>
                <li>이용자가 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 본인에게 있습니다.</li>
                <li>이용자는 진학어플라이 사이트에 자신이 게시한 게시물을 사용ㆍ복제ㆍ수정ㆍ출판ㆍ배포 할 수 있습니다.</li>
              </ol>
              <h4>제 6장 기타</h4>
              <h5>제17조(양도의 금지)</h5>
              <p>회원은 서비스 이용권리를 타인에게 대여, 양도 또는 증여 등을 할 수 없으며, 또한 질권의 목적으로도 사용할 수 없습니다.</p>
              <h5>제18조(면책 및 배상)</h5>
              <ol>
                <li>“서비스”는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
                <li>“서비스”는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</li>
                <li>“서비스”는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다.</li>
                <li>“서비스”는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.</li>
                <li>“서비스”는 서비스 이용과 관련하여 가입자에게 발생한 손해 가운데 가입자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.</li>
              </ol>
              <h5>제19조(재판권 및 준거법)</h5>
              <ol>
                <li>“서비스”와 이용자 간에 발생한 분쟁에 관한 소송은 서울중앙지방법원을 관할법원으로 합니다.</li>
                <li>“서비스”와 이용자 간에 제기된 소송에는 대한민국 법을 적용합니다.</li>
              </ol>
              <h5>[부칙]</h5>
              <p>① 본 약관은 2023년 10월 23일부터 시행됩니다.</p>
            </div>
        </Modal.Body>
        <Modal.Footer>
            
          <Button variant="primary" onClick={props.handleClose} disabled={isScrolledToBottom}>
            위 약관을 숙지하였으며 이에 동의합니다.
          </Button>
        </Modal.Footer>
      </Modal>
  );
}