import React from 'react';
import { useScrollFadeIn } from '../../hooks/useScrollFadeIn';
import developer1Image from '../../img/developer1.jpg'; // 이미지 파일의 실제 경로에 따라 수정
import developer2Image from '../../img/developer2.jpg'; // 이미지 파일의 실제 경로에 따라 수정
import { PersonDescription, PersonImage, PersonName, PersonInfo, PersonWrapper, PersonTitle } from '../../components/Person';
export const Developers = () => {
    const animatedItem = {
        0: useScrollFadeIn('up', 0.6, 0.5),
        1: useScrollFadeIn('left', 0.6, 0.8),
        2: useScrollFadeIn('right', 0.6, 1.1),
    };
  return (
    <>
        <PersonTitle {...animatedItem[0]}>Developers</PersonTitle>
        <PersonWrapper>
            <PersonInfo {...animatedItem[1]}>
                <PersonImage src={developer1Image} alt="Developer 1" />
                <PersonName>안호성</PersonName>
                <PersonDescription>
                    Backend Developer, student of Hana Academy Seoul 13th.
                </PersonDescription>
                <PersonDescription>
                    Developed Backend of HEES with FastAPI, PostgreSQL.
                </PersonDescription>
            </PersonInfo>
            <PersonInfo {...animatedItem[2]}>
                <PersonImage src={developer2Image} alt="Developer 2" />
                <PersonName>안세용</PersonName>
                <PersonDescription>
                    Frontend Developer, student of Hana Academy Seoul 14th.
                </PersonDescription>
                <PersonDescription>
                    Developed Frontend of HEES with React.
                </PersonDescription>
            </PersonInfo>
        </PersonWrapper>
    </>
  );
};
