import styled from 'styled-components';

// 스타일드 컴포넌트를 이용하여 각각의 영역 디자인
export const PersonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
`;

export const FullPersonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100vh;
`;

export const PersonTitle = styled.h1`
  text-align: center;
  margin-top: 20px;
`;

export const PersonInfo = styled.div`
  width: 70vh;
  height: 70vh;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-right:20px;
  margin-left:20px;
`;

export const PersonImage = styled.img`
  width: 70%;
  margin-left: 15%;
  border-radius: 4px;
`;

export const PersonName = styled.h2`
  margin-top: 10px;
`;

export const PersonDescription = styled.p`
  margin-top: 10px;
  color: #555;
`;