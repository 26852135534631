import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { serverURL } from "../../serverURL";
import { Spinner, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import '../../style/assignmodal.css'
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-clock/dist/Clock.css';
import 'react-time-picker/dist/TimePicker.css';

export function ApplierManageModal(props) {
    const client = axios.create()
    const [downloadBtnTxt, setDownloadBtnTxt] = useState('지원서 다운로드')
    const [startDate, setStartDate] = useState(new Date);
    const [loc, setLoc] = useState('')
    const [endDate, setEndDate] = useState(new Date);
    const [startTime, setStartTime] = useState('12:00')
    const [endTime, setEndTime] = useState('12:00')
    const [message, setMessage] = useState('')
    const [isGot, setIsGot] = useState('미입력')
    const [closeBtnTxt, setCloseBtnTxt] = useState('적용 및 닫기')

    useEffect(() => {
      if (props.info.date_start && props.info.date_start !== '미입력') {
        let temp = new Date(props.info.date_start)
        setStartDate(temp)
        setStartTime(`${temp.getHours()}:${temp.getMinutes()}`)
      }
      if (props.info.date_end && props.info.date_end !== '미입력') {
        let temp = new Date(props.info.date_end)
        setEndDate(temp)
        setEndTime(`${temp.getHours()}:${temp.getMinutes()}`)
      }
      if (props.info.got && props.info.got !== '미입력') {
        if (props.info.got === '합격') {
          setIsGot('1')
        } else {setIsGot('0')}
      }
      if (props.info.loc && props.info.loc !== '미입력') {
        setLoc(props.info.loc)
      }
      if (props.info.message && props.info.message !== '미입력') {
        setMessage(props.info.message)
      }
    },[props.info])


    const send = () => {
      setCloseBtnTxt('적용중...')
      let startDateTime = new Date(startDate)
      startDateTime.setHours(startTime.split(':')[0] + 9)
      startDateTime.setMinutes(startTime.split(':')[1])
      let endDateTime = new Date(endDate)
      endDateTime.setHours(endTime.split(':')[0] + 9)
      endDateTime.setMinutes(endTime.split(':')[1])
      client.post(serverURL+'/club/change_status', {
          'id':props.info.id,
          'order':props.info.order,
          'location': loc,
          'num':props.info.num,
          'date_start':startDateTime,
          'date_end':endDateTime,
          'got':isGot
      }, {
          'headers': {
              "Authorization": `Bearer ${props.token}`
          }
      })
      .then(res=>{
          setCloseBtnTxt('적용 및 닫기')
          props.handleClose()
          if (isGot !== '미입력') {
            client.post(serverURL+'/club/change_message', {
              'id':props.info.id,
              'num':props.info.num,
              'order':props.info.order,
              'message':message
            },{
              'headers': {
                "Authorization": `Bearer ${props.token}`
            }
            })
          }
      })
      .catch(err=>{
        if (err.response.status === 401) {
          props.logOut()
        }
          console.log(err)
          alert(err.response.data.detail)
          setCloseBtnTxt('적용 및 닫기')
      })
      props.handleClose()
    }


    const onDownload = () => {
      setDownloadBtnTxt('로딩중...')
      client.post(serverURL+'/club/download', {
          'id':props.info.id,
          'club_name':props.info.club_name,
          'order':props.info.order,
          'num':props.info.num
      }, {
          'headers': {
              "Authorization": `Bearer ${props.token}`
          },
          'responseType' : 'blob'
      })
      .then(res=>{
          setDownloadBtnTxt('지원서 확인')
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = `${props.info.name}_${props.info.club_name}_${props.info.order}차-${props.info.num}_지원서.pdf`;
          a.click();
      })
      .catch(err=>{
        if (err.response.status === 401) {
          props.logOut()
        }
          console.log(err)
          alert('파일을 불러오는데에 오류가 발생했습니다')
          setDownloadBtnTxt('지원서 확인')
      })
  }

  return (
      <Modal show={props.show} size="lg" id='assignModal' onHide={send}>
        <Modal.Header closeButton>
          <Modal.Title>{props.clubName} 지원 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table>
                <thead>
                  <tr>
                      <td>지원자</td>
                      <td>{props.info.id}{props.info.name}</td>
                  </tr>
                  <tr>
                    <td>지원 차수</td>
                    <td>{props.info.order}차 지원</td>
                  </tr>
                  <tr>
                    <td>특이사항</td>
                    <td>{props.info.doc_date}</td>
                  </tr>
                  <tr>
                    <td>면접 장소</td>
                    <td><input placeholder='면접 장소를 입력하세요' onChange={(e) => setLoc(e.target.value)} value={loc}/></td>
                  </tr>
                  <tr>
                    <td>면접 시작 일시</td>
                    <td><span>날짜:</span><DatePicker selected={startDate} onChange={(e)=>{setStartDate(e)}} dateFormat='yyyy/MM/dd'/><div id='timepickercontainer'><span>시간:</span><TimePicker onChange={(e) => {setStartTime(e)}} value={startTime}/></div></td>
                  </tr>
                  <tr>
                    <td>면접 종료 일시</td>
                    <td><span>날짜:</span><DatePicker selected={endDate} onChange={(e)=>{setEndDate(e)}} dateFormat='yyyy/MM/dd'/><div id='timepickercontainer'><span>시간:</span><TimePicker onChange={(e) => setEndTime(e)} value={endTime}/></div></td>
                  </tr>
                  <tr> 
                    <td>합불 여부 결정</td>
                    <td>
                      <select value={isGot} onChange={(e)=> setIsGot(e.target.value)}>
                        <option value='미입력'>미정</option>
                        <option value='1'>합격</option>
                        <option value='0'>불합격</option>
                      </select>
                    </td>
                  </tr>
                  {isGot !== '미입력' && <tr><td>합불 메세지 입력</td><td><textarea value={message} onChange={(e)=>setMessage(e.target.value)}/></td></tr>}
                </thead>
            </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={onDownload}>{downloadBtnTxt}</Button>
          <Button variant="secondary" onClick={send}>
            {closeBtnTxt}
          </Button>
        </Modal.Footer>
      </Modal>
  );
}