import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Spinner, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import '../../style/assignmodal.css'
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-clock/dist/Clock.css';
import 'react-time-picker/dist/TimePicker.css';
import { serverURL } from "../../serverURL";

export function ApplierManageModal(props) {
    const client = axios.create()
    const [downloadBtnTxt, setDownloadBtnTxt] = useState('지원서 다운로드')
    const [startDate, setStartDate] = useState(new Date);
    const [loc, setLoc] = useState('')
    const [endDate, setEndDate] = useState(new Date);
    const [startTime, setStartTime] = useState('12:00')
    const [endTime, setEndTime] = useState('12:00')
    const [message, setMessage] = useState('')
    const [isGot, setIsGot] = useState('미입력')
    const [closeBtnTxt, setCloseBtnTxt] = useState('닫기')

    useEffect(() => {
      if (props.info.date_start && props.info.date_start !== '미입력') {
        let temp = new Date(props.info.date_start)
        setStartDate(temp)
        setStartTime(`${temp.getHours()}:${temp.getMinutes()}`)
      }
      if (props.info.date_end && props.info.date_end !== '미입력') {
        let temp = new Date(props.info.date_end)
        setEndDate(temp)
        setEndTime(`${temp.getHours()}:${temp.getMinutes()}`)
      }
      if (props.info.got && props.info.got !== '미입력') {
        if (props.info.got === '합격') {
          setIsGot('1')
        } else {setIsGot('0')}
      }
      if (props.info.loc && props.info.loc !== '미입력') {
        setLoc(props.info.loc)
      }
      if (props.info.message && props.info.message !== '미입력') {
        setMessage(props.info.message)
      }
    },[props.info])


    const onDownload = () => {
      setDownloadBtnTxt('로딩중...')
      client.post(serverURL+'/club/download', {
          'id':props.info.id,
          'club_name':props.info.club_name,
          'order':props.info.order,
          'num':props.info.num
      }, {
          'headers': {
              "Authorization": `Bearer ${props.token}`
          },
          'responseType' : 'blob'
      })
      .then(res=>{
          setDownloadBtnTxt('지원서 확인')
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = `${props.info.name}_${props.info.club_name}_${props.info.order}차-${props.info.num}_지원서.pdf`;
          a.click();
      })
      .catch(err=>{
        if (err.response.status === 401) {
          props.logOut()
        }
          console.log(err)
          alert('파일을 불러오는데에 오류가 발생했습니다')
          setDownloadBtnTxt('지원서 확인')
      })
  }

  return (
      <Modal show={props.show} size="lg" id='assignModal' onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.clubName} 지원 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table>
                <thead>
                  <tr>
                      <td>지원자</td>
                      <td>{props.info.id}{props.info.name}</td>
                  </tr>
                  <tr>
                    <td>지원 차수</td>
                    <td>{props.info.order}차 지원</td>
                  </tr>
                  <tr>
                    <td>특이사항</td>
                    <td>{props.info.doc_date}</td>
                  </tr>
                  <tr>
                    <td>면접 장소</td>
                    <td>{loc ? loc : '미정'}</td>
                  </tr>
                  <tr>
                    <td>면접 시작 일시</td>
                    <td>{startDate.getFullYear() + '년 ' + `${startDate.getMonth() === 0 ? '12' : startDate.getMonth()}` + '월 ' + startTime}</td>
                  </tr>
                  <tr>
                    <td>면접 종료 일시</td>
                    <td>{endDate.getFullYear() + '년 ' + `${endDate.getMonth() === 0 ? '12' : endDate.getMonth()}` + '월 ' + endTime}</td>
                  </tr>
                  <tr> 
                    <td>합불 여부</td>
                    <td>
                    {isGot}
                    </td>
                  </tr>
                  {isGot !== '미입력' && <tr><td>합불 메세지 입력</td><td><textarea value={message} onChange={(e)=>setMessage(e.target.value)}/></td></tr>}
                </thead>
            </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={onDownload}>{downloadBtnTxt}</Button>
          <Button variant="secondary" onClick={props.handleClose}>
            {closeBtnTxt}
          </Button>
        </Modal.Footer>
      </Modal>
  );
}