import axios from "axios"
import Button from 'react-bootstrap/Button';
import '../../style/main.css'
import React, { useState, useEffect } from 'react';
import { ApplierManageModal } from "./AppliersManageModal";
import { Sidebar } from "../../components/Sidebar";
import Table from 'react-bootstrap/Table';
import { SimpleButtonWrapper, DownloadButtonWrapper } from "../../components/Buttons";
import { FaDownload } from 'react-icons/fa';
import { MdRefresh } from 'react-icons/md';
import styled from 'styled-components';
import { serverURL } from "../../serverURL";

export function MemberMain(props) {
    const client = axios.create()
    const [appliers, setAppliers] = useState({1:[{'id': '', 'name': 'loading...'}],2:[{'id': '', 'name': 'loading...'}],3:[{'id': '', 'name': 'loading...'}]})
    const [applyNum, setApplyNum] = useState(1)
    const [show, setShow] = useState(false)
    const [downloadButtonTexts, setDownloadButtonTexts] = useState([])
    const [downloadAllTxt, setDownloadAllTxt] = useState('지원서 전체 다운로드')
    const [applierInfo, setApplierInfo] = useState({
      'id':0,
      'club_name':props.userInfo.club_name,
      'order':1,
      'num':0,
      'name': ''
    })

    const handleClose = () => {
      setShow(false)
    }

    const downloadAll = () => {
      setDownloadAllTxt('로딩중...')
      client.post(serverURL+'/club/download_all',{'id':0,'club_name':props.userInfo.club_name, 'order':applyNum, 'num':0},{
        headers: {
            Authorization: `Bearer ${props.token}`
        },
        responseType: 'blob'
      })
      .then(blob=>{
        setDownloadAllTxt('지원서 전체 다운로드')
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${props.userInfo.club_name}_${applyNum}차_지원서.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(err=>{
        setDownloadAllTxt('지원서 전체 다운로드')
        alert('파일을 불러오는데에 오류가 발생했습니다')
        console.log(err)
        if (err.response.status === 401) {
          props.logOut()
        }
      })
    }

    const appliersRefresh = () => {
      setAppliers({1:[{'id': '', 'name': 'loading...'}],2:[{'id': '', 'name': 'loading...'}],3:[{'id': '', 'name': 'loading...'}]})
        client.get(`${serverURL}/club/appliers?order=${applyNum}`, {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        })
        .then((res) => {
          let temp = res.data
          setAppliers(temp)
        }).catch((err) => {
          if (err.response.status === 401) {
            props.logOut()
          }
          alert('지원자를 불러오는 데에 오류가 발생했습니다')
        })
        setApplierInfo({
          ...applierInfo,
          'order':applyNum
        })
    }

    useEffect(()=>{
      let temp = []
      for (let i=0; i<appliers[applyNum].length; i++) {
        temp[i] = '지원서 다운로드'
      }
      setDownloadButtonTexts(temp)
    },[appliers])

    useEffect(() => {
      appliersRefresh()
    },[applyNum])

    const onClick = (e) => {
      setApplierInfo({
        ...applierInfo,
        ...appliers[applyNum][e.target.id],
        'id' : e.target.name.slice(0,5),
        'name' : e.target.name.slice(5,e.target.name.length-1),
        'num' : parseInt(e.target.name[e.target.name.length-1]),
        'order' : applyNum,
        'club_name':props.userInfo.club_name
      })
      setShow(true)
    }

    const onDownload = (e) => {
      let temp = [...downloadButtonTexts];
      temp[e.target.id.slice(1,2)] = '로딩중...'
      setDownloadButtonTexts(temp)
      client.get(serverURL+'/club/download', {
          'params' : {
            'id':e.target.name.slice(0,5),
            'club_name':props.userInfo.club_name,
            'order':applyNum,
            'num':e.target.id.slice(0,1)
          },
          'headers': {
              "Authorization": `Bearer ${props.token}`
          },
          'responseType' : 'blob'
      })
      .then(res=>{
        temp[e.target.id.slice(1,2)] = '지원서 다운로드'
        setDownloadButtonTexts(temp)
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = `${e.target.name}_${props.userInfo.club_name}_${applyNum}차-${e.target.id.slice(0,1)}_지원서.pdf`;
          a.click();
      })
      .catch(err=>{
        temp[e.target.id.slice(1,2)] = '지원서 다운로드'
        setDownloadButtonTexts(temp)
          console.log(err)
        if (err.response.status === 401) {
          props.logOut()
        }
          alert('파일을 불러오는데에 오류가 발생했습니다')
      })
  }

  const RefreshButtonWrapper = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;


    return(
        <div id="leadermaincontainer">
          <Sidebar onButtonClick={setApplyNum} />
          <div id="leadermaincontents">
            <h2>{props.userInfo.club_name}의 {applyNum}차 지원자 현황:</h2>
            <RefreshButtonWrapper onClick={appliersRefresh}>
              <MdRefresh style={{ marginRight: '5px' }} />
              새로고침
            </RefreshButtonWrapper>
            <hr/>
            <Button variant="primary" onClick={downloadAll}><FaDownload/>{downloadAllTxt}</Button><Table responsive="sm">
            <thead>
              <tr>
                <th>학번 및 이름</th>
                <th>특이사항</th>
                <th>합불 여부</th>
                <th>세부 정보</th>
                <th>지원서 다운로드</th>
              </tr>
            </thead>
            <tbody>
            {appliers[applyNum].length ? appliers[applyNum].map((applier, index) => {return <tr><td>{applier.id+applier.name}</td><td>{applier.doc_date}</td><td>{applier.got}</td><td><SimpleButtonWrapper className="manageButton" id={index} onClick={onClick} name={applier.id+applier.name+applier.num}>세부사항</SimpleButtonWrapper></td><td><DownloadButtonWrapper name={applier.id+applier.name} id={applier.num + index.toString()} onClick={onDownload}><FaDownload/>{downloadButtonTexts[index]}</DownloadButtonWrapper></td></tr>}) : <tr><td/><td/><td>지원자가 없습니다</td><td/><td/></tr>}
            </tbody>
          </Table>
            
          <ApplierManageModal show={show} handleClose={handleClose} info={applierInfo} token={props.token} logOut={props.logOut}/>
          </div>
        </div>
      )
}