import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import { Spinner } from "react-bootstrap";
import { FileUploader } from 'react-drag-drop-files';
import '../../style/assign.css'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { serverURL } from "../../serverURL";

export function Assign(props) {
    const client = axios.create()
    const id = useParams().id
    const navigate = useNavigate()
    const [clubList, setClubList] = useState([])
    const [buttonTxt, setButtonTxt] = useState('지원하기')
    const [buttonDisable, setButtonDisable] = useState(true)
    const [fileName, setFileName] = useState('')
    const [cancelUploadButton, setCancelUploadButton] = useState('')
    const [uploadMsg, setUploadMsg] = useState(null)
    const[assignMsg, setAssignMsg] = useState(null)
    const [isUploadSuccess, setIsUploadSuccess] = useState('success')
    const [isApplySuccess, setIsApplySuccess] = useState('success')
    const [data, setData] = useState({
        'id':props.userInfo.id,
        'order':0,
        'num':0,
        'club_name':''
    })
    const [file, setFile] = useState(null)

    useEffect(()=> {
        client.get(serverURL+'/club/club_list',{
            'headers': {
                "Authorization": `Bearer ${props.token}`
            }})
        .then(res=>{
            setClubList(res.data['club_list'])
        })
        .catch(err=>{
            if (err.response.status === 401) {
                props.logOut()
            }
        })
        setData({
            ...data,
            'order' : id[0],
            'num': id[1]
        })
    },[])

    useEffect(()=>{
        if (props.role !== 'user') {

        }
    },[])

    useEffect(()=>{
        if(file !== null) {
            setFileName(file.name)
            setCancelUploadButton(<Button variant='danger' id='cancelupload' onClick={cancelUploadOnClick}>파일 선택 취소</Button>)
        } else {
            setFileName('')
            setCancelUploadButton('')
        }
    },[file])

    useEffect(()=> {
        if(file !== null && data.order !== 0 && data.num !== 0 && data.club_name !== '') {
            setButtonDisable(false)
        } else {
            setButtonDisable(true)
        }
    }, [file, data])

    const cancelUploadOnClick = () => {
        setFileName('')
        setFile(null)
    }


    const onClick = () => {
        if(data.order !== 0 && data.num !== 0 && data.club_name !== '' && file !== null) {
            setButtonTxt(<div>
                <Spinner  animation="border"/>
                지원서 업로드중...
            </div>)
            const formData = new FormData();
            formData.append("file", file);
            client.post(`${serverURL}/club/apply`, data, {
                    'headers': {
                    "Authorization": `Bearer ${props.token}`
                }})
                .then(res=>{
                    client.post(`${serverURL}/club/upload${data.num}`, formData, {
                        'headers': {
                        "Authorization": `Bearer ${props.token}`
                    }})
                    .then(res=>{
                        setButtonTxt('지원 완료')
                        navigate('/')
                    })
                    .catch(err=>{
                        console.log(err)
                        if (err.response.status === 401) {
                            props.logOut()
                        }else {
                            setAssignMsg(err.response.data.detail)
                            setIsApplySuccess('failed')
                            setButtonTxt('지원하기')
                        }
                    })
                })
                .catch(err=>{
                    console.log(err)
                    if (err.response.status === 401) {
                        props.logOut()
                    }else {
                        alert('지원은 성공하였으나 지원서 업로드에 오류가 발생했습니다. 메인 화면에서 지원서를 다시 제출해주세요.')
                        setAssignMsg(err.response.data.detail)
                        setIsApplySuccess('failed')
                        setButtonTxt('지원하기')
                    }
                    
                })
            
        }
    }


    const onChange = (e) => {
        if(e.target.name === 'club_name') {
            setData({
                ...data,
                [e.target.name]:e.target.value
            })
        } else {
            setData({
                ...data,
                [e.target.name]:parseInt(e.target.value)
            })
        }
    }

    return(
        <dev>
            <Container className="panel" id="assigncontainer">
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="assignOrderSelect">
                        <Form.Select aria-label="Default select example" name='order' onChange={onChange} value={data.order}>
                            <option name='0'>동아리 지원 기간 선택</option>
                            <option value='1' name='order'>1차 지원 기간</option>
                            <option value='2' name='order'>2차 지원 기간</option>
                            <option value='3' name='order'>3차 지원 기간</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="assignNumSelect">
                        <Form.Select aria-label="Default select example" name='num' onChange={onChange} value={data.num}>
                            <option name='0'>동아리 지원 번째 선택</option>
                            <option value='1' name='num'>첫번째 동아리</option>
                            <option value='2' name='num'>두번째 동아리</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="assignClubSelect">
                        <Form.Select aria-label="Default select example" name='club_name' onChange={onChange} value={data.club_name}>
                            <option name='select'>동아리 선택</option>
                            {clubList.map((elem, idx)=>{return(<option value={elem} name='club_name'>{elem}</option>)})}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>지원서 파일 선택 (pdf)</Form.Label>
                        <br/>
                        {(file === null) ? <FileUploader
                            id='filelabel'
                            name="file"
                            types={['PDF']}
                            multiple={false}
                            onDrop={(file) =>{setFile(file)}}
                            onSelect={(file) =>{setFile(file)}}
                            onChange={(file) =>{setFile(file)}}
                        /> : null}
                        <label id='filename'>{fileName}</label>
                        {cancelUploadButton}
                    </Form.Group>
                    <div className="d-grid gap-1" >
                        <Button variant="secondary" onClick={onClick} disabled={buttonDisable}>
                            {buttonTxt}
                        </Button>
                    </div>
                    <label className={isUploadSuccess}>{uploadMsg}</label>
                    <br/>
                    <label className={isApplySuccess}>{assignMsg}</label>
                </Form>
            </Container>
        </dev>
    )
}