import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import '../../style/clubs.css'
import { serverURL } from "../../serverURL";

export function Clubs(props) {
    const [clubs, setClubs] = useState([])
    const client = axios.create()

    useEffect(()=> {
        client.get(serverURL+'/club/club_list',{
            'headers': {
                "Authorization": `Bearer ${props.token}`
            }})
        .then(res=>{
            setClubs(res.data['club_list'])
        })
        .catch(err=>{
            if (err.response.status === 401) {
                props.logOut()
            }
        })
    },[])

    return(
        <div id="clubscontainer">
            <h1>동아리별 소개</h1>
            <hr/>
            {clubs.length === 0 ? <h3>loading...</h3> : clubs.map((club)=>{return <div><Link to={`/club/${club}`} className="clubs"><h3 className="club">{club}</h3></Link><hr/></div>})}
        </div>
    )
}