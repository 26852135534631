// Sidebar.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCalendarAlt } from 'react-icons/fa';

const SidebarWrapper = styled.div`
  width: 250px;
  height: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

const SidebarButton = styled.button`
  display: block;
  color: ${({ isActive }) => (isActive ? '#ffcc00' : '#fff')}; /* Active 버튼 스타일 변경 */
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 10px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`;

const TitleText = styled.h1`
  display: block;
  color: ${({ isActive }) => (isActive ? '#ffcc00' : '#fff')}; /* Active 버튼 스타일 변경 */
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
`;

export const Sidebar = ({ onButtonClick }) => {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonValue) => {
    setActiveButton(buttonValue);
    onButtonClick(buttonValue); // 부모 컴포넌트에 클릭한 버튼의 값을 전달
  }; 

  const StyledCalendarIcon = styled(FaCalendarAlt)`
  color: white; /* 원하는 색상으로 변경 */
  font-size: 18px; /* 원하는 크기로 변경 */
  margin-right: 8px; /* 원하는 마진 설정 */
`;

  return (
    <SidebarWrapper>
      <TitleText>지원 기간 선택</TitleText>
      <SidebarButton isActive={activeButton === 1} onClick={() => handleButtonClick(1)}><StyledCalendarIcon />
        1차 지원 확인
      </SidebarButton>
      <SidebarButton isActive={activeButton === 2} onClick={() => handleButtonClick(2)}><StyledCalendarIcon />
        2차 지원 확인
      </SidebarButton>
      <SidebarButton isActive={activeButton === 3} onClick={() => handleButtonClick(3)}><StyledCalendarIcon />
        3차 지원 확인
      </SidebarButton>
    </SidebarWrapper>
  );
};
