import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../style/nav.css'
import { useEffect } from 'react';
import { Link,useNavigate,useLocation} from 'react-router-dom';
import Logo from '../img/HASlogo.png';
export function Navi(props) {
    const onClick = () => {
        props.logOut()
    }
    const loc = useLocation()
    const navigate = useNavigate()
    useEffect(()=>{
      if(props.token === '' && loc.pathname !== '/login' && loc.pathname !== '/register') {
        navigate('/login')
      }
    }, [props.token, loc.pathname])
    if(props.token === '') {
        return (
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
              <Container>
                <Navbar.Brand>
                    <Link to='/' className='navlink'>HEES</Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link id='navclub'><Link to='/club' className='navlink'></Link></Nav.Link>
                  </Nav>
                  <Nav>
                    <Nav.Link id='navlogin'><Link to='/login' className='navuserlink'>로그인</Link></Nav.Link>
                    <Nav.Link id='navresister'><Link to='/register' className='navuserlink'>회원가입</Link></Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          );
    } else if (props.userInfo.role === 'Leader') {
        return (
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
              <Container>
                <Navbar.Brand>
                    <Link to='/' className='navlink'>HEES</Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto" id='navgroup'>
                    <Nav.Link id='navclub'><Link to='/clubs' className='innernavlink'>동아리별 소개</Link></Nav.Link>
                    {/* <Nav.Link id='navnotification'><Link to='/chat/' className='innernavlink'>채팅</Link></Nav.Link> */}
                    <Nav.Link id='navaboutus'><Link to='/aboutus' className='innernavlink'>About Us</Link></Nav.Link>
                  </Nav>
                  <Nav id='logoutwrap'>
                    <Nav.Link id='navlogout' onClick={onClick}>로그아웃({props.userInfo.name})</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          );
    } else if (props.userInfo.role === 'Member') {
      return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand>
                <Link to='/' className='navlink'>HEES</Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto" id='navgroup'>
                <Nav.Link id='navclub'><Link to='/clubs' className='innernavlink'>동아리별 소개</Link></Nav.Link>
                <Nav.Link id='navaboutus'><Link to='/aboutus' className='innernavlink'>About Us</Link></Nav.Link>
              </Nav>
              <Nav id='logoutwrap'>
                <Nav.Link id='navlogout' onClick={onClick}>로그아웃({props.userInfo.name})</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
    }
     else {
      return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand>
                <Link to='/' className='navlink'>HEES</Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto" id='navgroup'>
                <Nav.Link id='navclub'><Link to='/clubs' className='innernavlink'>동아리별 소개</Link></Nav.Link>
                <Nav.Link id='navassign'><Link to='/assign/00' className='innernavlink'>지원하기</Link></Nav.Link>
                {/* <Nav.Link id='navnotification'><Link to='/chat/' className='innernavlink'>채팅</Link></Nav.Link> */}
                <Nav.Link id='navaboutus'><Link to='/aboutus' className='innernavlink'>About Us</Link></Nav.Link>
              </Nav>
              <Nav id='logoutwrap'>
                <Nav.Link id='navlogout' onClick={onClick}>로그아웃({props.userInfo.name})</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
    }
  
}
